import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createReduxHistoryContext, reachify } from 'redux-first-history';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { globalHistory } from '@reach/router';

import { attachEpicMiddlewareToStore } from '@agile-actors/actions-engine/client';
import { injectReducer } from '@agile-actors/client/commons/store';
import {
  isBrowser,
  isCmsRoute,
  compose,
} from '@agile-actors/client/commons/utils';

const reachHistoryAdapter = (reachHistory) => {
  const originalHistory = isBrowser()
    ? createBrowserHistory(window)
    : createMemoryHistory();

  const adaptedHistory = Object.create(originalHistory);

  adaptedHistory.listen = (callback) => {
    reachHistory.listen(({ location }) => !isCmsRoute() && callback(location));

    originalHistory.listen((location) => {
      !isCmsRoute() &&
        reachHistory.navigate(location.pathname, { replace: true });
    });
  };

  return adaptedHistory;
};

const storeCache = {
  client: undefined,
  server: undefined,
};

const createStore = (preloadedState) => {
  if (isBrowser() && storeCache.client) {
    return storeCache.client;
  }

  if (!isBrowser() && storeCache.server) {
    return storeCache.server;
  }

  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history: reachHistoryAdapter(globalHistory),
    });

  const epicMiddleware = createEpicMiddleware();
  const middleware = [routerMiddleware, epicMiddleware];

  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools({
          name: 'Agile Actors Dev',
          trace: true,
          traceLimit: 25,
        })
      : compose;

  const store = reduxCreateStore(
    () => ({}),
    preloadedState || {},
    composeEnhancers(applyMiddleware(...middleware)),
  );

  injectReducer(store, {
    router: routerReducer,
  });

  isBrowser() ? (storeCache.client = store) : (storeCache.server = store);
  attachEpicMiddlewareToStore(store, epicMiddleware);

  store.history = reachify(createReduxHistory(store));

  return store;
};

export default createStore;
